:root {
  --default-button-border-radius: 6px;
}

:global(.Button) {
  display: inline-block;
  box-sizing: border-box;
  text-decoration: none;
  padding: 0.5rem 0.75rem;
  background: transparent;
  border: 1px solid color-mod(var(--color-border) blackness(5%));
  color: var(--color-text-dark);
  cursor: pointer;
  font-weight: bold;
  font-family: var(--default-font-family), sans-serif;
  border-radius: var(--default-button-border-radius);
}

:global(.Button:hover) {
  color: var(--color-brand);
  border-color: color-mod(var(--color-border) blackness(12%));
  background: var(--color-bg-light);
  transition: all 200ms linear;
  transition-property: color, border-color, background-color;
}

:global(.Button:focus) {
  outline: 2px solid var(--color-focus);
}

:global(.Button:focus:not(:focus-visible)) {
  outline: none;
}

@media screen and (--breakpoint-min-lg) {
  :global(.Button) {
    padding: 0.75rem 1rem;
  }
}

:global(.Button--small) {
  padding: 0.45rem 1rem;
}

:global(.Button--medium) {
  padding: 0.5rem 0.75rem;
  font-size: 0.8rem;
}

:global(.Button--large) {
  padding: 0.8rem 1.25rem;
  font-size: 1rem;
}

:global(.Button-normal) {
  font-weight: normal;
}

:global(.Button--round) {
  border-radius: 99px;
}

:global(.Button--primary) {
  color: var(--color-text-white);
  background: var(--color-brand);
  border: 1px solid var(--color-brand);
}

:global(.Button--primary:hover) {
  color: var(--color-text-white);
  border-color: var(--color-brand);
  background-color: var(--color-brand-alpha-88);
}

:global(.Button--warning) {
  color: var(--color-text-white);
  background: var(--color-error);
  border: 1px solid var(--color-error);
}

:global(.Button--warning:hover) {
  color: var(--color-text-white);
  border-color: var(--color-error);
  background-color: var(--color-error);
}

:global(.Button--cancel) {
  border-radius: 99px;
}

:global(.Button--white) {
  background-color: white;
  color: var(--color-text-dark);
  border-color: var(--color-border);
}

:global(.Button--borderless) {
  border-color: transparent;
  background: transparent;
  color: var(--color-text-medium);
}
:global(.Button--borderless:hover) {
  border-color: transparent;
  color: var(--color-text-medium);
}

:global(.Button--onlyIcon) {
  border: none;
  background: transparent;
  color: var(--color-text-dark);
  padding: 0;
}

:global(.Button-group) {
  display: inline-block;
  border-radius: var(--default-button-border-radius);
  border: 1px solid var(--color-border);
  overflow: hidden;
  clear: both;
}

:global(.Button-group .Button) {
  margin: 0;
  float: left;
  border-top: none;
  border-bottom: none;
  border-right: none;
  border-radius: 0;
  box-shadow: none;
}

:global(.Button-group .Button--active) {
  background-color: var(--color-success);
  color: var(--color-text-white);
}

:global(.Button-group .Button:first-child) {
  border-left: none;
}

:global(.Button-group--brand) {
  border-color: white;
}

:global(.Button-group--brand .Button) {
  border-color: white;
  color: var(--color-brand);
  background-color: var(--color-bg-medium);
}

:global(.Button-group--brand .Button--active) {
  background-color: var(--color-brand);
  color: white;
}

:global(.Button:disabled) {
  opacity: 0.5;
  cursor: not-allowed;
}

:global(.Button--danger) {
  background-color: var(--color-error);
  border-color: var(--color-error);
  color: var(--color-text-white);
}

:global(.Button--danger:hover) {
  color: white;
  background-color: var(--color-error);
  border-color: var(--color-error);
}

:global(.Button--success) {
  background-color: var(--color-success);
  border-color: var(--color-success);
  color: var(--color-text-white);
}
:global(.Button--success:hover) {
  background-color: var(--color-success);
  border-color: var(--color-success);
  color: var(--color-text-white);
}

:global(.Button--fullWidth) {
  width: 100%;
}
