/* @layout */
.QueryBuilder {
  transition: margin-right 0.35s;
}

/* a section of the graphical query itself */
:global(.Query-section) {
  display: flex;
  align-items: center;
}

:global(.Query-label) {
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 700;
  color: var(--color-text-medium);
}

:global(.Query-filters) {
  max-width: 400px;
}

:global(.Query-filterList) {
  display: flex;
  overflow-y: hidden;
  white-space: nowrap;
}

:global(.Filter-section) {
  display: flex;
  align-items: center;
  flex-shrink: 0;
}

:global(.TooltipFilterList .Query-filterList) {
  flex-direction: column;
}

:global(.TooltipFilterList .Query-section) {
  margin-left: -0.5rem;
}

/*
    @selectionmodule
*/
:global(.SelectionModule) {
  color: var(--color-brand);
}

:global(.SelectionList) {
  padding-top: 5px;
  overflow-y: auto;
  max-height: 340px;
}

:global(.SelectionItems) {
  max-width: 320px;
}

:global(.SelectionItems.SelectionItems--open) {
  opacity: 1;
  transition: opacity 0.3s linear;
  pointer-events: all;
}

:global(.SelectionItems.SelectionItems--expanded) {
  max-height: inherit;
}

:global(.SelectionItem) {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0.75rem 1.5rem 0.75rem 0.75rem;
  background-color: var(--color-bg-white);
}

:global(.SelectionItem:hover) {
  background-color: currentColor;
}

:global(.SelectionItem .Icon) {
  margin-left: 0.5rem;
  margin-right: 0.75rem;
  color: currentcolor;
}

:global(.SelectionItem .Icon-check) {
  opacity: 0;
}

:global(.SelectionItem .Icon-chevrondown) {
  opacity: 1;
}

:global(.SelectionItem:hover .Icon) {
  color: var(--color-text-white) !important;
}

:global(.SelectionItem:hover .SelectionModule-display) {
  color: var(--color-text-white);
}

:global(.SelectionItem:hover .SelectionModule-description) {
  color: var(--color-text-white);
}

:global(.SelectionItem.SelectionItem--selected .Icon-check) {
  opacity: 1;
}

:global(.SelectionModule-display) {
  color: currentColor;
  margin-bottom: 0.25em;
}

:global(.SelectionModule-description) {
  color: var(--color-text-medium);
  font-size: 0.8rem;
}

:global(.Visualization) {
  transition: background 0.3s linear;
}

:global(.Visualization.Visualization--loading) {
  transition: background 0.3s linear;
}

:global(.Visualization.Visualization--error) {
  justify-content: center;
}

:global(.Loading) {
  background-color: color-mod(var(--color-bg-white) alpha(-28%));
  transition: opacity 0.5s;
}
:global(.Loading.Loading--hidden) {
  background-color: transparent;
}

:global(.QueryError) {
  flex-direction: column;
  justify-content: center;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}

:global(.QueryError-image) {
  background-repeat: no-repeat;
  margin-bottom: 1rem;
}

:global(.QueryError-image--noRows) {
  width: 120px;
  height: 120px;
  background-image: url("~assets/img/no_results.svg");
}

:global(.QueryError-image--queryError) {
  width: 120px;
  height: 120px;
  background-image: url("~assets/img/no_understand.svg");
}

:global(.QueryError-image--serverError) {
  width: 120px;
  height: 148px;
  background-image: url("~assets/img/blown_up.svg");
}

:global(.QueryError-messageText) {
  line-height: 1.4;
}

:global(.QueryError-adminEmail) {
  position: relative;
  display: inline-block;
  border-radius: var(--default-border-radius);
  border: 1px solid var(--color-border);
  margin-top: var(--margin-2);
  padding: var(--padding-1) var(--padding-4) var(--padding-1) var(--padding-4);
}

:global(.QueryError-adminEmail:before) {
  content: "Admin Email";
  font-size: 10px;
  text-align: center;
  text-transform: uppercase;
  background-color: white;
  padding-left: var(--padding-1);
  padding-right: var(--padding-1);

  position: absolute;
  top: -0.75em;
  left: 50%;
  margin-left: -41px; /* ugh */
}

:global(.QueryError2) {
  padding-top: 4rem;
  margin-left: auto;
  margin-right: auto;
}

:global(.QueryError2-details) {
  max-width: 500px;
}

:global(.GuiBuilder) {
  position: relative;
  display: flex;
  flex-direction: column;
  font-size: 0.9em;
  z-index: 2;
  background-color: var(--color-bg-white);

  border: 1px solid var(--color-border);
}

/* for medium breakpoint only expand if data reference is not shown */
@media screen and (--breakpoint-min-md) {
  :global(.GuiBuilder) {
    font-size: 1em;
  }
}

/* un-expanded (default) */
:global(.GuiBuilder-row) {
  border-bottom: 1px solid var(--color-border);
}
:global(.GuiBuilder-row:last-child) {
  border-bottom-color: transparent;
}
:global(.GuiBuilder-data) {
  border-right: 1px solid var(--color-border);
}
:global(.GuiBuilder-filtered-by) {
  border-right: 1px solid transparent;
}
:global(.GuiBuilder-view) {
  border-right: 1px solid var(--color-border);
}

/* expanded */
:global(.GuiBuilder.GuiBuilder--expand) {
  flex-direction: row;
}
:global(.GuiBuilder.GuiBuilder--expand .GuiBuilder-row:last-child) {
  border-right-color: transparent;
  border-bottom-color: var(--color-border);
}
:global(.GuiBuilder.GuiBuilder--expand .GuiBuilder-filtered-by) {
  border-right-color: var(--color-border);
}

:global(.GuiBuilder-section) {
  position: relative;
  min-height: 55px;
  min-width: 100px;
}

:global(.GuiBuilder-section-label) {
  background-color: white;
  position: absolute;
  top: -7px;
  left: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

/* DATA SECTION */

:global(.GuiBuilder-data) {
  z-index: 1; /* moved the arrow thingy above the filter outline */
}

/* VISUALIZATION SETTINGS */

:global(.VisualizationSettings .GuiBuilder-section) {
  border-right: none !important;
}

:global(.RunButton) {
  opacity: 1;
  transition: transform 0.25s, opacity 0.25s;
}

:global(.RunButton.RunButton--compact) {
  padding: 16px 32px;
}

:global(.RunButton.RunButton--hidden) {
  transform: translateY(-20px);
  opacity: 0;
}

@media (prefers-reduced-motion) {
  :global(.RunButton),
  :global(.RunButton.RunButton--compact),
  :global(.RunButton.RunButton--hidden) {
    transition-duration: 10ms;
  }
}

/* DATA REFERENCE */

:global(.ObjectJSON) {
  max-height: 200px;
  overflow: scroll;
  padding: 1em;
  background-color: var(--color-bg-light);
  border: 1px solid var(--color-border);
  border-radius: 2px;
  white-space: pre-wrap;
}

:global(.List-item:not(.List-item--disabled):hover .FieldList-grouping-trigger),
:global(.List-item--selected .FieldList-grouping-trigger) {
  visibility: visible;
}

:global(.QuestionTooltipTarget) {
  color: var(--color-text-light);
  border: 2px solid currentColor;
  border-radius: 99px;
  width: 20px;
  height: 20px;
  display: flex;

  align-items: center;
  justify-content: center;
  opacity: 0.7;

  cursor: pointer;
}

:global(.QuestionTooltipTarget:after) {
  content: "?";
  font-size: 13px;
  font-weight: bold;
}

/* need to do this ugliness to override the locally scoped font size from Scalar.css */
:global(.QueryBuilder .ScalarValue) {
  font-size: 5em;
}
